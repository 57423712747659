.atsmosphere {
    background-color: #fbf2f2;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 20px;
  }

.atsmosphere h1{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 48px;
    line-height: 55px;
    color: #756659;
    margin-left: 20px;
}
.img-container {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
}

.atsmosphere-img-container {
  display: flex; 
  box-sizing: border-box;
  width: 100%;
  height: 75vh; 
  justify-content: center;
  gap: 15px;
}

.button-container {
  position: relative;
  display: inline-block;
}

.fat-cat {
  object-fit: contain;
  height: 90px;
  display: block;
}

.control-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px; /* Adjust font size as needed */
  background-color: #f3cfce;
  color: #756659;
  border: none;
  cursor: pointer;
}

  .image-circles {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .image-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #999; 
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; 
  }
  
  .image-circle.active {
    background-color: #333; 
  }

  @media (max-width: 1600px) {


    .atsmosphere-img-container {
      height: 65vh; 

    }
}

@media (max-width: 1000px) {
  .atsmosphere {
    padding-left: 15px;
    padding-right: 15px; }


}

@media (max-width: 650px) {
  .atsmosphere-img-container {
    width: auto;
  }

}

@media (max-width: 525px) {


    .atsmosphere h1{
      font-size: 30px;
      line-height: 45px;
  }
}