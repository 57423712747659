
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
  height: 200px;
  gap: 10%;
}

.footer-img {
  max-width: 120vh; 
  max-height: 40vh;
  object-fit: contain;
}

.footer span {
  text-align: center;
  display: block; 
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 25px;
  color: #756659;
}

.social-icons {
    display: flex;
    justify-content: center; 
    align-items: center; 
    gap: 10px; 
  }
.facebook-icon {
    height: 59px;
    width: 59px;
}

.insta-icon {
    height: 55px;
    width: 55px;
}

.tiktok-icon {
    height: 60px;
    width: 60px;
}

@media (max-width: 850px) {
  .footer-img {
    width: 100vh; 
    height: 20vh;
  }
}

@media (max-width: 850px) {

  .footer-img {
    width: 100vh; 
    height: 20vh;
  }
}

@media (max-width: 600px) {

  .footer{
    margin-left: 0px ;
    margin-right: 0px ;
    margin-top: 10px;
  }

  .footer-img {
    width: 60vh; 
    height: 10vh;
  }
}

@media (max-width: 600px) {

  .footer span {
    font-size: 15px;
    line-height: 28px;
  }

  .facebook-icon {
    height: 54px;
    width: 54px;
}

.insta-icon {
    height: 50px;
    width: 50px;
}

.tiktok-icon {
    height: 55px;
    width: 55px;
}
}

@media (max-width: 375px) {

  .footer{

    margin-top: 5px;
  }

  .footer-img {
    width: 45vh; 
    height: 8vh;
  }

  .footer span {
    font-size: 12px;
    line-height: 18px;
  }

  .facebook-icon {
    height: 44px;
    width: 44px;
}

.insta-icon {
    height: 40px;
    width: 40px;
}

.tiktok-icon {
    height: 45px;
    width: 45px;
}
}