.about-us{
    padding-top: 0px;
    padding-bottom: 25px;
    padding-right: 50px;
    padding-left: 50px;
    align-items: center;
    background-color: #fbf2f2;

}

.about-us h1{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 48px;
    line-height: 55px;
    color: #756659;
}

.about-info-container{
    display: flex;
    gap: 20px;
    padding: 20px;
}

.about-info-container img{
    max-width: 30%;
    height: auto;
    border-radius: 5px;

}
.about-info {
    padding: 10px;
    display: inline-block;
  }

.about-us p{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 25px;
    color: #756659;
}


@media (max-width: 1200px) {

    .about-info-container img{
        max-width: 35%;
    }
}
@media (max-width: 1100px) {

    .about-us{
        padding-bottom: 15px;
        padding-right: 20px;
        padding-left: 20px;
   
    }

    .about-info-container img{
        max-width: 50%;
        height: 100%;
    }

    .about-info {
        padding: 0px;
      }
}

@media (max-width: 1000px) {


    .about-info-container img{
        max-width: 48%;
        height: 100%;
    }
}

@media (max-width: 900px) {


    .about-info-container{
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
    }

    .about-info-container img{
        max-width: 100%;
        height: 60vh;
    }
}

@media (max-width: 525px) {


      .about-us h1{
        font-size: 30px;
        line-height: 45px;
    }

    .about-us p{
        font-size: 15px;
        line-height: 20px;
    }

}


@media (max-width: 350px) {
    .about-us p{
        font-size: 12px;
        line-height: 18px;
    }

}