.banner-img {
    width: 100%;
}

.cats{
    padding-left: 50px;
}

.infos{
    padding-left: 50px;
}

.discounts{
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
}
.homepage h1{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 48px;
    line-height: 55px;
    color: #756659;
}

@media (max-width: 525px) {

    .cats{
        padding-left: 20px;
    }

    .infos{
        padding-left: 20px;
    }
      .homepage h1{
        font-size: 30px;
        line-height: 45px;
    }

    .discounts{
        padding-left: 20px;
        padding-right: 20px;
    }
}


@media (max-width: 350px) {

      .homepage h1{
        font-size: 30px;
        line-height: 45px;
    }

}