.discount {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 50px;
    padding-left: 50px;
    padding-bottom: 30px;
    gap: 20px;
}

.discount h2{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 34px;
    line-height: 40px;
    color: #756659;
}
.discount p{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 25px;
    color: #756659;
}

.discount-img{
    height: 60vh;
    width: 40vh;
}

.program-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 800px) {
    .discount{
        display: flex;
        flex-direction: column;
    }
    
    .discount-img{
        height: 70vh;
        width: 50vh;
    }
}



@media (max-width: 525px) {

    .discount-img{
        height: 60vh;
        width: 40vh;
    }


    .discount{
        padding-right: 20px;
        padding-left: 20px;
    }
    
      .discount h2{
        font-size: 28px;
        line-height: 33px;
    }

    .discount p{
        font-size: 15px;
        line-height: 20px;
    }


}

@media (max-width: 350px) {

    
      .discount h2{
        font-size: 25px;
        line-height: 30px;
    }

    .discount p{
        font-size: 12px;
        line-height: 18px;
    }


}