.store-info{
    display: flex;
    padding-top: 0px;
    padding-right: 50px;
    padding-left: 50px;
    align-items: center;

}

.store-info h1{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 48px;
    line-height: 55px;
    color: #756659;
}
.store-map {
    height: 80%;
    width: 100%;
  }

.store-basic{
    margin-right: 30px;
    margin-left: 0px;
}
  .map-container {
    height: 50%;
    width: 50%;
    padding: 50px;
  }

.basic-info{
    display: flex;
    flex-direction: row;
    padding-right: 50px;
    gap: 10px;
    border-bottom: 1px solid #756659;
}

.basic-info:last-child{
    border-bottom: none;
}
.store-info p{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 25px;
    color: #756659;
}

.info-description{
    text-align: left;
}

.info-title{
    min-width: 150px; 
    flex-shrink: 0; 
}

@media (max-width: 1500px) {
    
    .store-info{
        padding-right: 20px;
        padding-left: 20px;
    
    }

    .map-container {
        height: 50%;
        width: 70%;
      }

}

@media (max-width: 1280px) {


    .map-container {
        height: 70%;
        width: 100%;
        padding: 10px;
      }

      .store-basic{
        margin-right: 0px;
        margin-left: 20px;
    }

}

@media (max-width: 1024px) {
    .store-info{
        display: flex;
        flex-direction: column;
    }

    .map-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70%;
        width: 70%;
        padding: 0px;
      }


}

@media (max-width: 560px) {


    .store-basic{
        margin-top: 0px;
    }

    
    .map-container {
        height: 20%;
        width: 100%;
        padding: 0px;
      }

      .store-info h1{
        font-size: 30px;
        line-height: 45px;
    }

    .basic-info{
        padding-right: 10px;
        gap: 5px;
    }

    .store-info p{
        font-size: 15px;
        line-height: 20px;
    }

    .info-title{
        flex: 0 1 auto;
        min-width: 80px;
        max-width: 80px; 
    }
}


@media (max-width: 350px) {

    .store-info{
        padding-right: 20px;
        padding-left: 20px;
    }
    
    .map-container {
        height: 10%;
        width: 100%;
        padding: 0px;
      }

      .store-info h1{
        font-size: 30px;
        line-height: 45px;
    }

    .basic-info{
        padding-right: 0px;
    }

    .store-info p{
        font-size: 12px;
        line-height: 18px;
    }

    .info-title{
        flex: 0 1 auto;
        min-width: 54px;
        max-width: 54px;    }

}