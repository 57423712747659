.concept {
    color: #756659;
  }
  
  
  .concept-banner {
    width: 100%;
  }
  
  .banner h1 {
    position: absolute;
    top: 25%;
    left: 55%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    font-weight: 600;
    font-family: 'Ubuntu', sans-serif;
  }

  .body{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 120px;
    }
.concept-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 10px;
}

.concept-text {
    max-width: 50%; 
    padding: 80px;
    margin-right: 50px;
    margin-left: -30px;
    background-color: #fbf2f2;

  }

.concept-text h2 {
    font-size: 2em;
    margin-bottom: 0.5em;

}

.concept-text p {
    font-size: 1.2em;
    line-height: 1.5;

}

.concept-img-right {
    max-width: 50%;
    z-index: 1;
    margin-left: -10%;

}

.concept-img-left {
    max-width: 50%;
    max-height: 500px;
    z-index: 2;
    margin-right: 10px;
}

.concept-img-right img {
    max-width: 100%;
    object-fit: contain;
}

.concept-img-left img {
    max-width: 100%;
    object-fit: contain;

}

@media (max-width: 1400px) {

  .body{
    margin: 50px;
    padding-bottom: 0px;
  }

  .concept-content{
    display: flex;
    flex-direction: column;
  }

  .concept-text {
    max-width: 50%; 
    padding: 80px;
    margin-right: 0px;
    margin-left: 0px;

  }

  .concept-content {
    z-index: 3;
  }

  .concept-img-right img {
    width: 100%;
    overflow: hidden;
     }
  
    .concept-img-right img {
      width: 100%;
      overflow: hidden;
      }

}

@media (max-width: 525px) {
    .body{
      margin: 20px;
      padding: 0px;
    }

    .concept-text {
      max-width: 100%; 
      padding: 10px;
    }

    .concept h1{
      font-size: 30px;
      line-height: 45px;
      top: 10%;
      left: 55%;
  }

  .concept p{
      font-size: 15px;
      line-height: 20px;
  }

    .concept h2{
      font-size: 28px;
      line-height: 33px;
  }

  .concept-img-left {
    max-width: 70%;

}
.concept-img-right {
  max-width: 70%;

}
}

@media (max-width: 350px) {

  
    .concept h2{
      font-size: 25px;
      line-height: 30px;
  }

  .concept p{
      font-size: 12px;
      line-height: 18px;
  }




}