.cats-profiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; 
    padding: 50px;
    padding-top: 50px;
    gap: 20px; 
    text-align: center;
}

.cat-info{
    text-align: center;
}

.cat-pics{
    height: 280px;
    width: 280px;
    overflow: hidden;
}

.cat-info h1{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    color: #756659;
    line-height: 1; 
}
.cat-info p{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    color: #756659;
    line-height: 1; 
}


@media (max-width: 599px) {

    .cats-profiles {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around; 
        padding: 10px;
        gap: 10px; 
        text-align: center;
    }
    

    .cat-info{
        max-width: 40%;
        text-align: center;
    }
    .cat-pics{
        height: 140px;
        width: 140px;
        flex-wrap: wrap;

    }
    
    .cat-info h1{
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        color: #756659;
        line-height: 0.5; 
    }
    .cat-info p{
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-style: normal;
        font-weight: 450;
        font-size: 10px;
        color: #756659;
        line-height: 0.5; 
    }
}


