.review-img {
  width: 100%;
}

.review-banner h1 {
  position: absolute;
  top: 15%;
  left: 65%;
  color: #756659;
  font-size: 80px;
  font-weight: 600;
  font-family: 'Ubuntu', sans-serif;
}

.review-body{
  margin: 100px;
  margin-bottom: 50px;
}
.review-info {
  background-color: none;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
    font-family: arial;
    border-bottom: 0.1px solid grey;
  } 
  .review-info :last-child{
    border-bottom: none;
  }
  .review-head {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .reviewer-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .reviewer-name {
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    color: #333;
  }
  
  .star-rating {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding-left: 50px;
  }


  .star-rating span {
    font-size: 20px;
    line-height: 1;
    margin-right: 8px;
  }
  
  .review-text {
    font-size: 15px;
    color: #666;
    line-height: 1.4;
    margin-bottom: 8px;
    padding-left: 50px;
  } 

  @media (max-width: 700px) {
    .review-body{
      margin: 50px;
    }

  }
  @media (max-width: 525px) {
    .review-body{
      margin: 20px; }
    .review h1{
      font-size: 30px;
      line-height: 45px;
      top: 10%

  }

  .review p{
      font-size: 15px;
      line-height: 20px;
  }


}

@media (max-width: 350px) {


  .review p{
      font-size: 12px;
      line-height: 18px;
  }




}
  