.faq {
    margin: 0 auto;
    padding-bottom: 50px;
  }
  
  .faq-banner {
    position: relative;
    text-align: center;
    margin-bottom: 20px; 
  }
  
  .faq-img {
    width: 100%;
    display: block;
  }
  
  .faq-banner h1 {
    position: absolute;
    top: 50%;
    left: 85%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 80px;
    font-weight: 600;
    font-family: 'Ubuntu', sans-serif;
  }
  
  .faq-tabs{
    justify-content: space-around;
    text-align: center;  
    margin: 100px;
    margin-bottom: 50px;
    display: flex}

  .tab-button {
    width: 50%;
    padding: 10px;
    border: 0.2px solid #756659;
    border-top: none;
    background-color: white;
    cursor: pointer;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 21px;
    line-height: 50px;
    color: #756659;
  }

  
  .tab-button.active {
    border-top: 0.4px solid #756659;
    border-bottom: none;
    font-weight: bold;
  }

  .faq-content{
    padding-right: 100px;
    padding-left: 100px;
    padding-bottom: 50px;

  }

  .faq-question {
    font-family: 'Arial', sans-serif;
    margin-top: 25px;
    padding: 30px;
    font-size: 1.2rem;
    font-weight: 500;
    color: #39312b;
    background-color: #fbf2f2;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }
  
  /* Answer Styles */
  .faq-answer {
    font-family: 'Arial', sans-serif;
    color: #39312b;
    font-size: 1.2rem;
    padding: 30px;
  }
  
  .icon-open {
    /* Make the - icon larger */
    font-size: 24px;
    font-weight: bolder;
  }
  
  .icon-closed {
    font-size: 46px;
    font-weight: 100;
  }
  
  @media (max-width: 700px) {
    .faq-tabs{
      margin: 50px; 
    }
    .faq-content{
      padding-right: 50px;
      padding-left: 50px;
    }
  }
  @media (max-width: 525px) {
    .faq-tabs{
      margin: 20px; }

      .faq-content{
        padding-right: 20px;
        padding-left: 20px;
      }

    .faq h1{
      font-size: 30px;
      line-height: 45px;
  }

  .faq p{
      font-size: 15px;
      line-height: 20px;
  }


}

@media (max-width: 350px) {


  .faq p{
      font-size: 12px;
      line-height: 18px;
  }




}
  