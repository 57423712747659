.header-right {
  justify-content: center;
  margin-right: 60px

}
.menu-container {
  position: relative;
}
.fat-cat-img {
  height: 150px;
}

.menu-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px; 
  background-color: transparent;
  color: #756659;
  border: none;
  cursor: pointer;
}

.overlay {
  display: none; 
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white; 
  z-index: 1000; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay.show {
  display: flex; 
}

.closeButton {
  border: none;
  background-color: transparent;
  font-weight: lighter;
  font-size: 50px; 
  position: absolute;
  top: 20px; 
  right: 20px; 
  cursor: pointer;
}

.closeButton:focus {
  outline: none;
}

.header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  background-color: transparent;
  border-bottom: none; 
  align-items: center;

}

.header.sticky {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);
}

  
  .header.white {
    background-color: none;
    transition: background-color 0.3s ease, border-bottom-color 0.3s ease;

  }
  
  .header-left {
    display: flex;
    align-items: center;
    width: 80%;
    margin-left: 60px
  }
  
  .header-logo {
    object-fit: contain;
    height: 120px;
    margin-right: 30px;
    margin-left: 0px;

  }

  .header-lang {
    width: 100px;
    height: 20px;
    font-size: 16px;
    border: 2px solid #756659;
    border-radius: 30px;
    color: #756659;
    cursor: pointer;
    background: #FFF;
    padding: 5px;
  }
  
  .nav-bar {
    position: absolute;
    top: 60px;
    right: 30px;
    display: flex;
    justify-content: space-evenly;
    gap: 5px;
  }
  
  
  .menuOption {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    color: #756659;
    cursor: pointer;  
    transition: color 0.3s ease;
  }

  .menuOption-icon {
    color: #756659;
    transform: scale(1.8);
  }
  
  .menuOption-title {
    object-fit: contain;
    color: #756659;
    font-size: 24px;
    font-family: "system-ui"; 
  }
  
  .menuOption:hover, .menuOption:hover .menuOption-icon, .menuOption:hover .menuOption-title {
    color: black; 
  }
 

  @media (max-width: 800px) {
    

    .header-left > img {
      object-fit: contain;
      height: 80px;
      margin-right: 30px;
      margin-left: 0px;
  
    }


    .fat-cat-img {
      height: 94px;
      width: auto; 
    }

    .menu-button {
      font-size: 14px; 
    }

    .header-lang {
      width: 85px;
      height: 18px;
      font-size: 15px;
      padding: 5px;
    }

    .menuOption {
      margin-right: 20px;
    }
  
    .menuOption-icon {
      transform: scale(1.2);
    }
    
    .menuOption-title {
      font-size: 14px;
    }

  }

  @media (max-width: 600px) {
    
    .header-left > img {
      height: 70px;
      margin-right: 10px;
      margin-left: 0px;
    }
    .header-lang {
      width: 75px;
      height: 15px;
      font-size: 13px;
      padding: 3px;}
      
      .menuOption {
        margin-right: 20px;
      }
    
      .menuOption-icon {
        transform: scale(1.1);
      }
      
      .menuOption-title {
        font-size: 13px;
      }
    
  }

  @media (max-width: 450px) {

    .header-left{
      margin-left: 30px;
    }

    .header-right{
      margin-right: 30px;
    }

    
    .fat-cat-img {
      height: 94px;
      width: auto; 
    }

    .menu-button {
      font-size: 14px; 
    }

    .header-left > img {
      height: 55px; 
      margin-right: -10px;
    }
      .header-lang {
        width: 77px;
        height: 16px;
        font-size: 10px;
        padding: 2px;
      }

      .custom-menu.ant-dropdown-menu {
        font-size: 10px !important; 
      }

      
  }

  @media (max-width: 350px) {

    .fat-cat-img {
      height: 70px;
      width: auto; 
    }

    .menu-button {
      font-size: 9px; 
    }

    .header-left > img {
      height: 50px; 
    }

    .header-lang {
      width: 64px;
      height: 14px;
      font-size: 9px;
      padding: 1px;}
      
  }

  